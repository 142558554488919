import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import "../../App.css";

function Slider() {
  const firstSlide = (
    <Carousel.Item>
      <section className="hero-section" id="carousel-hero-1">
        <div className="hero-section-content body-margin">
        <h3>GIVE YOUR LIFE. DON'T TAKE IT.</h3>
        </div>
      </section>
    </Carousel.Item>
  );

  const secondSlide = (
    <Carousel.Item>
      <section className="hero-section" id="carousel-hero-2">
      <div className="hero-section-content body-margin">
        <div id="carousel-ghandi-quote">
        <p>
          "The best way to find yourself is to
          lose yourself in the service of others."
        </p>
        <p class="blockquote-footer">Mahatma Gandhi </p>
        </div>
        </div>
      </section>
    </Carousel.Item>
  );

  const thirdSlide = (
    <Carousel.Item>
      <section className="hero-section" id="carousel-hero-3">
      <div className="hero-section-content body-margin">
        <p>
          <strong>If you are asking the question: </strong> Would it be better
          if I wasn’t here?
        </p>
        <p>
          <strong>The answer is: </strong> No one can take your place. People
          you haven’t met yet or who are not even born need you.
        </p>
        </div>
      </section>
    </Carousel.Item>
  );

  const fourthSlide = (
    <Carousel.Item>
      <section className="hero-section" id="carousel-hero-4">
      <div className="hero-section-content body-margin">
        <p>
          “Most of the important things in the world have been accomplished by
          people who have kept on trying when there seemed to be no hope at
          all.”
        </p>
        <p class="blockquote-footer">Dale Carnegie </p>
        </div>
      </section>
    </Carousel.Item>
  );

  return (
    <div>
      <Carousel interval={null}>
        {firstSlide}
        {secondSlide}
        {thirdSlide}
        {fourthSlide}
      </Carousel>
    </div>
  );
}

export default Slider;
