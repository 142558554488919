import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";

function Footer() {
  return (
    <footer id="footer-section">
        {/* <Nav>
      <Nav.Item>
        <Nav.Link href="/">Home</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/about-us">About Us</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/get-involved">Get Involved</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/find-help">Find Help</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/#contact-us">Contact Us</Nav.Link>
      </Nav.Item>
    </Nav> */}
    </footer>
  );
}

export default Footer;
