import React from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Volunteer from "../../assets/GIPartner.jpg";
import Partner from "../../assets/huddle.jpg";
import Event from "../../assets/GIEvent.jpg";

function HeroSection() {
  return (
    <section className="hero-section" id="get-involved-hero">
      <div class="hero-section-content body-margin">
        <p id="hero-first-p">
          “Pam Boyd is doing important and innovative work that cannot be
          ignored. Pam is passionate about preventing the unnecessary loss of
          life and is using her creativity to launch a new approach to suicide
          prevention.”{" "}
        </p>
        <p>-M’liss Jenkins, Washington County Wellness Initiative (CHIO) </p>
      </div>
    </section>
  );
}

function IntroSection() {
  return (
    <section class="body-margin">
      <p>
        <strong>GIVE YOUR LIFE. DON’T TAKE IT.</strong> expands the suicide
        prevention conversation to provide avenues for reciprocity and active
        giving as pathways to immediate usefulness and perspective for men,
        women, teens, vets, and seniors who stand on the suicidal trailheads of
        loss, feeling overwhelm, shame, and disconnect. Studies support this
        strategy of providing a way for suffers to be active-givers versus
        passive-receivers to reduce suicidal depression and ideation.
        <sup>1</sup>
      </p>

      <p>
        Obviously, breaking through suicidal despair is a daunting task. Yet,
        for those who hide their pain and intent, the odds of breaking through
        the fatal mental agony is nearly impossible. The simple redirection
        toward doing and giving will not stop suicide for every sufferer but
        will do so for such a significant number that it cannot be ignored. We
        need your help to accomplish our mission.
      </p>

      <footer>
        <ul>
          <li>
            <a
              href="https://www.icloud.com/iclouddrive/07e11STHpY1sThK7hNcVOESQg#White_Paper_Suicide_Prevention"
              target="_blank"
              rel="noopener noreferrer"
            >
              <sup>1</sup>GIVE YOUR LIFE. DON'T TAKE IT. White Paper (click to
              download)
            </a>
          </li>
        </ul>
      </footer>
    </section>
  );
}

function WaysToSection() {
  return (
    <section id="ways-to-section">
      <div className="body-margin">
        <h2>Ways to Get Involved</h2>
        <div id="opportunity-container">
          <article class="opportunity">
            <img className="opportunity-image" src={Event} alt='event'/>
            <h3>Host An Event</h3>
            <p class="card-text">
              Spread the word about suicide prevention and help grow community
              awareness.
            </p>
          </article>

          <article class="opportunity">
            <div>
              <img className="opportunity-image" src={Volunteer} alt='volunteers'/>
            </div>
            <h3>Organization That Needs Volunteers?</h3>
            <p class="card-text">Provide opportunities for active giving.</p>
          </article>

          <article class="opportunity">
            <div>
              <img className="opportunity-image" src={Partner} alt='partners'/>
            </div>
            <h3>Partner With Us</h3>
            <p class="card-text">
            Help those struggling with suicidal thoughts find a purpose and
              second chance at life.
            </p>
          </article>
        </div>
        <p>
          <a className="text-info" href="/#contact-form">
            <strong>Contact Us</strong>
          </a>
        </p>
      </div>
    </section>
  );
}

function GetInvolved() {
  return (
    <div>
      <HeroSection />
      <IntroSection />
      <WaysToSection />
    </div>
  );
}

export default GetInvolved;
