import React from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function Donate() {
    return(
        <div>
            Nothing here
        </div>
    )

}

export default Donate;