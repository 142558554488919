import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Form, Button, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../App.css";
import logo from "../../assets/logo.png";

function Navb() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" id="NavB">
      <Nav.Link className="nav-link" as={Link} to="/">
        <Navbar.Brand>
          <img id="logo" className="img-fluid" src={logo} alt='logo'/>
        </Navbar.Brand>
      </Nav.Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar">
        <Nav id="navLinks" className="mr-auto">
          <Nav.Link className="nav-link" as={Link} to="/">
            Home
          </Nav.Link>
          <Nav.Link className="nav-link" as={Link} to="/about-us">
            About Us
          </Nav.Link>
          <Nav.Link className="nav-link" as={Link} to="/get-involved">
            Get Involved
          </Nav.Link>
        </Nav>
        <Form inline>
          {/* <FormControl type="text" placeholder="Search" className="mr-sm-2" /> */}
          <Button as={Link} to="/find-help" id="nav-button-help">
            Find Help
          </Button>
          <a href="/#contact-form">
            <Button id="nav-button-contact">
              Contact Us
            </Button>
          </a>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navb;
