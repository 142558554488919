import React from "react";
import emailjs from "emailjs-com";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../../assets/oilseed.jpg";

export default function ContactUs() {
  function sendEmail(e) {
    e.preventDefault();

    //arguments('serviceId', 'templateId", 'userID')
    emailjs
      .sendForm(
        "Pammy Boyd",
        "template_btpv@vb",
        e.target,
        "user_5a4NESBHOs5w2ZSh96bhJ"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <section className="rounded-section body-margin" id="form-section">
      <h2>Contact Us</h2>
     
      <Form id="contact-form" onSubmit={sendEmail}>
      <p>Want to get in touch? Fill out the information below and we will be with you right away.</p>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Full name"
            name="name"
            required
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            required
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Subject</Form.Label>
          <Form.Control type="text" name="subject" required />
        </Form.Group>

        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>Write your message</Form.Label>
          <Form.Control as="textarea" rows="3" name="message" required />
        </Form.Group>

        <Button
          variant="info"
          type="submit"
          onClick={() => {
            alert("You've successfully sent us your information!");
          }}
        >
          Send Message
        </Button>
        
      </Form>
      <p>If this is an emergency, please call 911</p>
    </section>
  );
}
