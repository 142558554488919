import React from "react";

function Mission() {
  return (
    <section className="hero-section" id="mission-hero">
      <div className="hero-section-content body-margin">
        <p>
          Our mission is to expand the suicide prevention conversation to
          provide opportunities for active volunteering as a pathway to suicide
          prevention for men, women, teens, vets, and seniors who are
          experiencing an intense sense of loss, overwhelm, shame, or
          disconnect.
        </p>
      </div>
    </section>
  );
}

export default Mission;
