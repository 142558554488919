import React from "react";

function Video() {
  return (
    <article id="whywedoit-video">
      <iframe
        src="https://www.youtube.com/embed/4BZprZex1Ec?rel=0&amp;modestbranding=1&amp;autoplay=0"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; 
                picture-in-picture"
        title="GiveYourLife video"
        allowfullscreen
      ></iframe>
    </article>
  );
}

export default Video;
