import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import pam from "../../assets/pam-boyd.jpg";

function Bio() {
  return (
    <div className="rounded-section body-margin" id="founder-bio-section">
      <h2>About the Founder</h2>
        {/* Picture of Pam */}
          <img id="founder-pic" src={pam} alt="Pam Boyd"/>
        {/* Pam's Biography */}
          <div id="founder-bio-text">
          <p>
            Pam Boyd has been involved in suicide prevention for several years
            and on numerous fronts. Her current project is a dark comedy based
            on her experience with suicide.
          </p>
          <p>
            <em>As a trainer and coach</em>, she helps individuals get past
            their obstacles and on with their dreams by assisting with goal
            setting, self-confidence, forgiveness, self-love, and clarity.
          </p>
          <p>
            <em>As a speaker and a writer</em>, she hopes to increase awareness
            of the deadly nature of depression and introduce a new prevention
            idea that involves high-energy volunteer environments where new
            purpose and hope is introduced. In her essay, <a href="http://wp.me/p10vYS-2nC" target="_blank" rel="noopener noreferrer">
               Confessions of a Suicide Groupie,
            </a>{" "}
            she writes about her own experience with suicide. Contact us if you  would like a copy of her essay.
          </p>
          <p>
            <em>As a Crisis Intervention Specialist</em> volunteer and trainer
            for Contact Crisis Line.
          </p>
      </div>

      {/* Pam's Social Media Links*/}
      <div id="pamSocial">
        <a
          className="fab fa-2x fa-twitter px-3"
          href="http://twitter.com/PamGBoyd"
          target="_blank"
          rel="noopener noreferrer"
          alt='Twitter'
        > </a>

        <a
          className="fab fa-2x fa-blogger"
          href="https://pamboyd.wordpress.com/"
          target="_blank" 
          rel="noopener noreferrer"
          alt='Blog'
        > </a>
      </div>
    </div>
  );
}

export default Bio;
