import React from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, CardDeck } from "react-bootstrap";
import texasMentalHealth from "../../assets/texasMentalHealth.jpg";
import suicidePrevention from "../../assets/suicidePrevention.jpg";
import oklahomaYouthSuicidePrevention from "../../assets/oklahomaYouthSuicidePrevention.jpg";
import preventTeenSuicide from "../../assets/preventTeenSuicide.jpg";
import suicidePreventionResourceCenter from "../../assets/suicidePreventionResourceCenter.jpg";
import suicidePreventionAction from "../../assets/suicidePreventionAction.jpg";
import crisisTextLine from "../../assets/crisis-text-line.png"

function Resources() {
  return (
    <div className="resourcesMain">
      <h1>Find Help Now</h1>
      <div className="d-flex justify-content-center">
        <a id="phoneNumber" href="tel:1-800-273-8255">
          {" "}
          Call 1-800-273-TALK (8255)
        </a>
      </div>
      <h3>If this is an emegency, call 911.</h3>   

      {/* <Row className="d-flex justify-content-md-center">     */}
      <CardDeck className="resourcesCardDeck d-flex flex-wrap justify-content-center align-items-stretch text-center">
        {/* <Col xs={1} lg={12}></Col> */}
        <div className="resourcesCard">
          <a href="https://texassuicideprevention.org/">
            <Card.Img
              variant="top"
              className="cardImg hovereffect"
              src={suicidePrevention}
            />
          </a>
          <Card.Body>
            <Card.Title className="black-text">
              {" "}
              <a href="https://texassuicideprevention.org/">
                {" "}
                TX Mental Health and Substance Use Services{" "}
              </a>
            </Card.Title>
          </Card.Body>
        </div>

        <div className="resourcesCard">
          <a href="https://hhs.texas.gov/services/mental-health-substance-use">
            <Card.Img
              variant="top"
              className="cardImg hovereffect"
              src={texasMentalHealth}
            />
          </a>
          <Card.Body>
            <Card.Title className="black-text">
              <a href="https://hhs.texas.gov/services/mental-health-substance-use">
                TX Health Mental Health Support Line
              </a>
            </Card.Title>
          </Card.Body>
        </div>

        <div className="resourcesCard">
          <a href="https://www.ok.gov/odmhsas/Prevention_/Prevention_Initiatives/Youth_Suicide_Prevention_and_Early_Intervention_Initiative/index.html">
            <Card.Img
              variant="top"
              className="cardImg hovereffect"
              src={oklahomaYouthSuicidePrevention}
            />
          </a>
          <Card.Body>
            <Card.Title className="black-text">
              <a href="https://www.ok.gov/odmhsas/Prevention_/Prevention_Initiatives/Youth_Suicide_Prevention_and_Early_Intervention_Initiative/index.html">
                OK Youth Suicide Prevention Initiative{" "}
              </a>
            </Card.Title>
          </Card.Body>
        </div>

        {/* <Col xs={1} s={2} lg={12}></Col> */}
        <div className="resourcesCard">
          <a href="https://www.sptsusa.org/">
            <Card.Img
              variant="top"
              className="cardImg hovereffect"
              src={preventTeenSuicide}
            />
          </a>
          <Card.Body>
            <Card.Title className="black-text">
              {" "}
              <a href="https://www.sptsusa.org/">
                The Society for the Prevention of Teen Suicide
              </a>{" "}
            </Card.Title>
          </Card.Body>
        </div>

        <div className="resourcesCard">
          <a href="http://www.sprc.org/">
            <Card.Img
              variant="top"
              className="cardImg hovereffect"
              src={suicidePreventionResourceCenter}
            />
          </a>
          <Card.Body>
            <Card.Title className="black-text">
              {" "}
              <a href="http://www.sprc.org/">
                Suicide Prevention Resource Center
              </a>{" "}
            </Card.Title>
          </Card.Body>
        </div>

        <div className="resourcesCard">
          <a href="http://www.spanusa.org/">
            <Card.Img
              variant="top"
              className="cardImg hovereffect"
              src={suicidePreventionAction}
            />
          </a>
          <Card.Body>
            <Card.Title className="black-text">
              <a href="http://www.spanusa.org/">
                {" "}
                Suicide Prevention Action Network USA
              </a>{" "}
            </Card.Title>
          </Card.Body>
        </div>

       
        <div className="resourcesCard">
          <a href=" https://www.crisistextline.org/  ">
            <Card.Img
              variant="top"
              className="cardImg hovereffect"
              src={crisisTextLine}
            />
          </a>
          <Card.Body>
            <Card.Title className="black-text">
              <a href="https://www.crisistextline.org/  ">
                {" "}
                Crisis Text Line
              </a>{" "}
            </Card.Title>
          </Card.Body>
        </div>

       
      </CardDeck>
      {/* </Row> */}
    </div>
  );
}

export default Resources;
